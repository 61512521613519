import forum from "../assets/svg/projects/forum.jpg";
// import github from "../assets/svg/projects/Screenshot-8.png";
// import api from "../assets/svg/projects/926f6aaba773.png";
// import healeye from "../assets/svg/projects/harpreet-singh-ZI2Lv7jxmEM-unsplash.jpg";
import auth from "../assets/svg/projects/regularguy-eth-eNxYF6cexYU-unsplash.jpg";
import mahi from "../assets/svg/projects/photo_2024-01-25_00-20-11.jpg";
import eight from "../assets/svg/projects/appleReact.jpg";
import netflix from "../assets/svg/projects/netflix-clone.jpeg";
import amazon from "../assets/svg/projects/amazon-clone.jpeg";
import hotel from "../assets/png/hotel.jpg";
import crypto from "../assets/png/blockchain.jpg";
import bike from "../assets/png/bike.jpg";
import Gemstone from "../assets/newproject/wase.jpg";
import Master from "../assets/newproject/mastercrypto.jpg";
import Abe from "../assets/newproject/download (1).jpg";

export const projectsData = [
  {
    id: 21,
    projectName: "Master Crypto",
    projectDesc:
      "MasterCrypto is the best platform to find and join the latest airdrops. Get free tokens and coins by participating in airdrops from the top crypto projectsusing React, Node, Express, Django, Mysql",
    tags: ["NextJs", "Node", "Express", "Django", "Python"],
    code: "https://github.com/Nati-Developer",
    demo: "https://mastercrypto.me/",
    image: Master,
  },
  {
    id: 20,
    projectName: "Wase Gemstone",
    projectDesc:
      "using React, Node, Express, Django, Mysql",
    tags: ["React", "Node", "Express", "Django", ],
    code: "https://github.com/Nati-Developer",
    demo: "https://wasgem.nati16.com/",
    image: Gemstone,
  },
  {
    id: 7,
    projectName: "Crypto News and Market",
    projectDesc: "React Crypto currency database and bing news open source API You can't spell Crypto without CRY",
    tags: ["React", "Tailwind", "Rapid", "React-spinners"],
    // code: "https://github.com/Nati-Developer/Netflix-React-Clone",
    demo: "https://ru.nati16.com/",
    image: crypto,
  },

  {
    id: 22,
    projectName: "Abebe Garage Saas App",
    projectDesc:
      "",
    tags: ["React", "Node", "Express",],
    code: "https://abegarage.nati16.com/",
    demo: "https://abegarage.nati16.com/",
    image: Abe,
  },
  {
    id: 5,
    projectName: "Amazon Clone",
    projectDesc:
      "MERN amazon clone using React, Node, Express, Stipe and Firebase",
    tags: ["React", "Node", "Express", "Firebase", "Stripe"],
    code: "https://github.com/Nati-Developer/Amazon-Replica",
    demo: "https://clone-ae320.firebaseapp.com/",
    image: amazon,
  },
  {
    id: 4,
    projectName: "Netflix Clone",
    projectDesc: "React Netflix clone using the movie database open source API",
    tags: ["React", "TMDB", "Firebase", "React-spinners", "Bootstrap"],
    code: "https://github.com/Nati-Developer/Netflix-React-Clone",
    demo: "https://natiflix.nati16.com/",
    image: netflix,
  },
 
  { id: 6,
    projectName: "Mahi Shoe Shop",
    projectDesc:
      "Frontend React Tailwind Vitejs Website ",
    tags: ["React", "Tailwind CSS", "ViteJs",  "Material-UI"],
    code: "https://github.com/Nati-Developer/Mahlet-Nike-Shop",
    demo: "https://mahishope.netlify.app/",
    image: mahi,
  },
  { id: 8,
    projectName: "Nion Bike",
    projectDesc:
      "Frontend React Tailwind Vitejs Website The blue print of Mahi Shoe Shop",
    tags: ["React", "Tailwind CSS", "ViteJs",  "Material-UI"],
    // code: "https://github.com/Nati-Developer/Mahlet-Nike-Shop",
    demo: "https://heba.netlify.app/",
    image: bike,
  },
  {
    id: 3,
    projectName: "Apple website clone",
    projectDesc: "Apple clone with react components",
    tags: ["React", "Bootstrap", "Jquery"],
    code: "https://github.com/Nati-Developer/Apple-React",
    demo: "https://nati-apple.netlify.app/",
    image: eight,
  },
  {
    id: 1,
    projectName: "Evangadi Forum",
    projectDesc: "Constructed a dynamic question-and-answer forum from scratch",
    tags: ["React", "Mysql", "NodeJs"],
    code: "https://github.com/Nati-Developer/Evangadi-Forum-Backend",
    demo: "https://github.com/Nati-Developer/Evangadi-Forum-Replica",
    image: forum,
  },
  {
    id: 9,
    projectName: "Ethiopian Restorant",
    projectDesc: "Build a hotel website to booking and appointment",
    tags: ["Html", "Css", "Javascript", "NodeJs", "Express" ],
    // code: "https://github.com/Nati-Developer/Evangadi-Forum-Backend",
    demo: "https://lucyethiopia.netlify.app/",
    image: hotel,
  },
  // {
  //   id: 2,
  //   projectName: "Github Users",
  //   projectDesc:
  //     "This project is all about utilizing external API, github API.",
  //   tags: ["React", "React-Loader", "CSS"],
  //   code: "",
  //   demo: "",
  //   image: github,
  // },

  // {
  //   id: 6,
  //   projectName: "HealEye, Patient Management System",
  //   projectDesc:
  //     "complete MERN App, consisting of patient management, booking, expense Stats and More.",
  //   tags: ["React", "NodeJs", "Express", "MongoDB", "Material-UI"],
  //   code: "",
  //   demo: "",
  //   image: healeye,
  // },
  // {
  //   id: 7,
  //   projectName: "AlgoSpace, Modern E-Learning Platform",
  //   projectDesc:
  //     "Complete E-Learning System, with Resource, multiple User Accounts Roles, Authentication and Authorization ",
  //   tags: ["NodeJs", "Express", "MongoDB", "Pug", "JWT", "Node-Mailer"],
  //   code: "",
  //   demo: "",
  //   image: three,
  // },
  // {
  //   id: 8,
  //   projectName: "E-Learning, API",
  //   projectDesc:
  //     "This project is a Complete E-Learning API, to be consumed by frontEnd library's like ReactJs, Angular or Other",
  //   tags: ["NodeJs", "Express", "MongoDB", "JWT", "Node-Mailer"],
  //   code: "",
  //   demo: "",
  //   image: api,
  // },
  // {
  //   id: 9,
  //   projectName: "Store, API",
  //   projectDesc: "products store API",
  //   tags: ["NodeJS", "MongoDB", "Express"],
  //   code: "",
  //   demo: "",
  //   image: api,
  // },
  {
    id: 10,
    projectName: "Authentication System",
    projectDesc: "Session based authentication system",
    tags: ["NodeJS", "Express", "PassportJS", "EJS", "Bootstrap"],
    code: "",
    demo: "",
    image: auth,
  },
];

// Do not remove any fields.
// Leave it blank instead as shown below

/* 
{
    id: 1,
    projectName: 'Car Pooling System',
    projectDesc: '',
    tags: ['Flutter', 'React'],
    code: '',
    demo: '',
    image: ''
}, 
*/
