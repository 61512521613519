/* eslint-disable */
import girl1 from "../assets/svg/testimonials/girl1.svg";
import girl2 from "../assets/svg/testimonials/girl2.svg";
import girl3 from "../assets/svg/testimonials/girl3.svg";
import boy1 from "../assets/svg/testimonials/boy1.svg";
import boy2 from "../assets/svg/testimonials/boy2.svg";
import boy3 from "../assets/svg/testimonials/boy3.svg";
import img from "../assets/svg/testimonials/photo_2023-12-23_18-42-28-removebg-preview.png"
import img1 from "../assets/svg/testimonials/tube1.png"
import img2 from "../assets/svg/testimonials/derebest.png"
import jhon from "../assets/png/jhon3.jpg"
import elsa from "../assets/png/elza.jpg"
import saud from "../assets/png/saudi.jpg"

export const testimonialsData = [
  {
    id: 7,
    name: "Michael Carter",
    title: "Red Sea",
    text: "His ability to adapt to remote work environments and deliver high-quality results is impressive. He’s proactive, reliable, and always goes the extra mile to ensure success. Highly recommend!",
    image: saud, // Replace with an appropriate image or use a placeholder
  },
  {
    id: 1,
    name: "Dr. Getnet Bogale",
    title: "Data Science Engineer, CEO of Atrons",
    text: "Working with him has been an absolute pleasure. His dedication, expertise, and innovative mindset have been invaluable to our team. We are truly fortunate to have him on board!",
    image: img,
  },
  {
    id: 2,
    name: "Yohannes Bekele",
    title: "Innovator & Entrepreneur, CEO of Haddis Art",
    text: "A passionate and hardworking technologist who consistently delivers exceptional results. His ability to solve complex problems with creativity and precision is truly remarkable.",
    image: jhon,
  },
  {
    id: 3,
    name: "Elsabet",
    title: "Software Developer",
    text: "As a highly skilled and reliable developer, he brings a unique blend of technical expertise and collaborative spirit to every project. His work ethic and attention to detail are second to none.",
    image: elsa,
  },
  {
    id: 4,
    name: "Dereje Chinkl",
    title: "Machine Learning Engineer",
    text: "A quick learner and adaptable professional, he stays ahead of industry trends and continuously improves his craft. His humility and collaborative approach make him a joy to work with.",
    image: img2,
  },
  {
    id: 5,
    name: "Tewobsta Tewoflos",
    title: "Software Engineer",
    text: "A passionate and hardworking technologist, he is dedicated to driving innovation and delivering high-quality solutions. His commitment to excellence is truly inspiring.",
    image: img1,
  },
  
];