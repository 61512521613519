export const achievementData =
 {
  bio: "I consider every corner of my profession's or Expertise and works hard, studies hard on them ",
  achievements: [
    {
      id: 3,
      title: "MERN Stack Certification",
      details:
        "Compelete FrontEnd Technologies and BackEnd Systems with best security practices",
      date: "July 10, 2023",
      field: "Development",
      image:
        "https://images.pexels.com/photos/943096/pexels-photo-943096.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        // certificate: 'https://www.udemy.com/certificate/UC-d6fa1032-62fc-4949-8172-e99ebf3459bf/'
      },
    {
      id: 1,
      title: "Database Management System Certification ",
      details:
        "SQL and noSQL database design, implementation and management",
      date: "Aug 20, 2023",
      field: "Database",
      image:
        "https://images.unsplash.com/photo-1597852074816-d933c7d2b988?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8ZGF0YWJhc2V8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60",
    // certificate: 'Certificate',
      },
    {
      id: 2,
      title: "React",
      details:
        "React JS- Complete Guide for Frontend Web Development",
      date: "Aug 20, 2023",
      field: "Frontend Development ",
      image:
        "https://images.unsplash.com/photo-1577648188599-291bb8b831c3?q=80&w=1479&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    // certificate: 'Certificate',
      },
    // { 
    
    //   id: 5,
    //   title: "Introduction to Cyber Security Certification",
    //   details: "what are current lettest attacks and how to prevent them",
    //   date: "Feb 21, 2023",
    //   field: "Security",
    //   image:
    //     "https://www.istockphoto.com/photo/lock-network-technology-concept-gm1412282189-461777105?utm_campaign=srp_photos_top&utm_content=https%3A%2F%2Funsplash.com%2Fs%2Fphotos%2Fcyber-security&utm_medium=affiliate&utm_source=unsplash&utm_term=cyber+security%3A%3A%3A",
    //     certificate: 'https://www.udemy.com/certificate/UC-3e222726-3bd6-4bab-bb35-5afbcadc4544/',
   
      // },
    { 
    
      id: 4,
      title: "Introduction to Cyber Security Certification",
      details: "what are current lettest attacks and how to prevent them",
      date: "Feb 21, 2023",
      field: "Security",
      image:
        "https://images.unsplash.com/photo-1633265486064-086b219458ec?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        // certificate: 'https://www.udemy.com/certificate/UC-3e222726-3bd6-4bab-bb35-5afbcadc4544/',
   
      },
    { 
    
      id: 6,
      title: "Backend development",
      details: "Build a Backend REST API with Node JS from Scratch ",
      date: "sep 12, 2023",
      field: "Backend Development",
      image:
        "https://images.unsplash.com/photo-1627398242454-45a1465c2479?q=80&w=1374&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        // certificate: 'https://www.udemy.com/certificate/UC-3e222726-3bd6-4bab-bb35-5afbcadc4544/',
   
      },
    { 
    
      id: 7,
      title: "Introduction to AI",
      details: "Learn to understand Artificial Intelligence and Machine Learning algorithms, and learn the basics of Python Programming ",
      date: "Nov 21, 2023",
      field: "AI",
      image:
        "https://media.istockphoto.com/id/1345658982/photo/ai-microprocessor-on-motherboard-computer-circuit.jpg?s=1024x1024&w=is&k=20&c=SIzGFhl8DDCxaBUXkAOegQ9TecRA3Qp2vbJi5LCXtBU=",
        // certificate: 'https://www.udemy.com/certificate/UC-3e222726-3bd6-4bab-bb35-5afbcadc4544/',
   
      },
  ],
};
// Do not remove any fields.
// Leave it blank instead as shown below.

/*

export const achievementData = {
    bio : "",
    achievements : []
}

*/
